import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';

Vue.use(VueRouter);

const routes = [

    /**
     * Main app routes
     */
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/HomeView.vue'),
    },
    {
        path: "/:machine_name",
        name: "Dashboard",
        component: () => import('../views/DashboardView.vue'),
    },
    {
        path: "/:machine_name/settings",
        name: "Settings",
        component: () => import('../views/SettingsView.vue')
    }


];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach(async (to, from, next) => {

    const guest = to.matched.some(record => record.meta.guest);
    const auth = to.matched.some(record => record.meta.auth);

    // Do not allow user to visit login page page if they are logged in
    if (guest && store.getters.isLoggedIn) {
        return next('/');
    }
    // If access to routes requires permission
    if (to.meta.permission !== undefined) {
        if (!(await store.dispatch('hasPermission', to.meta.permission))) {
            return next('/page-not-found');
        }
    }

    next();
});


export default router
