import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        app_version: process.env.VUE_APP_VERSION,
        api_on_host: process.env.VUE_APP_API_ON_HOST,
        api_url: process.env.VUE_APP_API_URL,
        api_domain: process.env.VUE_APP_API_DOMAIN,

        // Api response notification
        show_response: false,
        response: null,
        response_type: 'success',
        response_timeout: -1,

        // Stores
        app_store: null,

        // Machines Options
        machine_name: '', // the machine name to be set from url
        machine_phase_num: 0, // number of machine phases
        machine_stats_options: [], // machine stats that are measured
        machine_stats_extra: [], // extra stats vars that are not associated with phases
        charts_options: [],

        // Machines Statistics
        // List of user selected options, on previous sessions (stored locally & retrieved on initial setup)
        optionsList: {
            machineName: '',
            options: [],
            friendly_names: [],
        },
        datasets: [],
        no_data_status: false,
        loading_status: false,
        cardsLoadingStatuses: [],
        refreshCounters: []
    },
    getters: {
        // Machines
        machineName: state => {
            return state.machine_name;
        },
        phaseSelectOptions: state => {
            let options = [];
            for (let i = 1; i <= state.machine_phase_num; i++) {
                options.push({ text: `Phase ${i}`, value: i });
            }
            return options;
        },

        getFriendlyName: (state) => (value) => {
            return state.optionsList.friendly_names[value] ?? value;
        },

        getFriendlyNames: (state) => (values) => {
            return values.map(val =>
                state.optionsList.friendly_names[val] ?? val);
        },

        simpleSelectOptions: state => {
            const machine_stats_options = [];
            state.machine_stats_extra.forEach(item => {
                if (item in state.optionsList.friendly_names) {
                    machine_stats_options.push({
                        text: state.optionsList.friendly_names[item] ?? item,
                        value: item
                    });
                } else {
                    machine_stats_options.push({
                        text: item,
                        value: item
                    });
                }
            });
            return machine_stats_options;
        },
        machineStatsOptions: (state) => {
            return state.machine_stats_options;
        },
        machineStatExtraOptions: (state) => {
            return state.machine_stats_extra;
        },
        machineStatsHeaders: state => {
            // Get machine stats from store & map them to create header objects for dt
            let headers = Object.keys(state.machine_stats_options)
                .map(key => ({ text: state.machine_stats_options[key], value: key, sortable: false }));

            // Prepend the "Phase" to the headers
            headers.unshift({ text: "PHASE", value: 'phase', sortable: false });

            return headers;
        },
        optionsList: state => {
            return state.optionsList;
        },
        // Get options for specified index
        getOption: (state) => (index) => {
            const optionset = state.optionsList['options'][index];

            return {
                'form_id': optionset.formId,
                'charts_selected_options': optionset.charts,
                'stats_selected_options': optionset.stats,
                'phase_selected_options': optionset.phases.length > 0
                    ? optionset.phases.map(phase => phase.phase)
                    : [],
                'selectedInterval': optionset.selectedInterval,
                'dates': optionset.dates,
                'title': optionset.title,
                'dt_items': optionset.phases,
                'autoRefresh': optionset.autoRefresh,
                'refreshInterval': optionset.refreshInterval,
                'excludeZeros': optionset.excludeZeros,
                'charts_options': structuredClone(optionset.charts_options),
            };
        },
        optionsetTitle: (state) => (index) => {
            return state.optionsList['options'][index].title;
        },

        chartIntervalSetting: (state) => (chart_name, setting) => {
            return state.charts_options[chart_name].settings.intervals[setting];
        },

        pieChartSettings: (state) => (setting) => {
            return state.charts_options['pie_chart'].settings[setting];
        },

        chartSelectOptions: (state) => {
            return Object.values(state.charts_options).map(({ text, value }) => ({ text, value }))
        },

        linePhaseColorPalette: (state) => {
            return state.charts_options['line_chart'].phase_color_palette;
        },

        fromDate: (state) => (index) => {
            return state.optionsList['options'][index].dates.from
        },
        toDate: (state) => (index) => {
            return state.optionsList['options'][index].dates.to
        },
        optionsListLength(state) {
            return state.optionsList['options'].length;
        }
    },
    mutations: {
        // Response Notification
        mutateShowResponse(state, payload) {
            state.show_response = payload;
        },
        mutateResponse(state, payload) {
            state.response = Array.isArray(payload)
                ? payload
                : [payload]
        },
        mutateResponseType(state, payload) {
            state.response_type = payload;
        },
        mutateResponseTimeout(state, payload) {
            state.response_timeout = payload;
        },

        // Machines
        mutateMachineName(state, payload) {
            state.machine_name = payload;
        },
        mutateOptionsList(state, payload) {
            state.optionsList.options = payload;
        },
        mutateOptionsFriendlyNames(state, payload) {
            state.optionsList.friendly_names = payload;
        },
        mutatateOptionsListMachineName(state, payload) {
            state.optionsList.machineName = payload;
        },
        initDatasetsArray(state) {
            state.datasets = Array(state.optionsList.options.length).fill(null);
        },
        mutateDatasets(state, payload) {
            state.datasets = payload;
        },
        addDataset(state, dataset) {
            state.datasets.push(dataset);
        },
        updateDataset(state, payload) {
            state.datasets[payload.index] = payload.data;
        },
        deleteDataset(state, index) {
            state.datasets.splice(index, 1);
        },

        // Options
        addOption(state, option) {
            state.optionsList['machineName'] = state.machine_name;
            state.optionsList['options'].push(option);
        },
        updateOption(state, payload) {
            state.optionsList['options'][payload.index] = payload.options;
        },
        updateOptionsListFriendyNames(state, payload) {
            state.optionsList['friendly_names'] = payload.friendly_names.reduce((accumulator, item) => {
                accumulator[item.value] = item.text;
                return accumulator;
            }, {});
        },
        initCardsLoadingStatuses(state) {
            state.cardsLoadingStatuses = Array(state.optionsList.options.length).fill(false);
        },
        mutateCardLoadingStatus(state, payload) {
            state.cardsLoadingStatuses[payload.index] = payload.status;
        },
        addCardLoadingStatus(state) {
            state.cardsLoadingStatuses.push(0);
        },
        removeCardLoadingStatus(state, index) {
            state.cardsLoadingStatuses.splice(index, 1);
        },

        initCardsRefreshCounters(state) {
            state.refreshCounters = Array(state.optionsList.options.length).fill(0);
        },
        incrementCardsRefreshCounter(state, payload) {
            state.refreshCounters[payload] += 1;
            state.refreshCounters = [...state.refreshCounters];
        },
        addCardRefreshCounter(state) {
            state.refreshCounters.push(0);
        },
        removeCardRefreshCounter(state, index) {
            state.refreshCounters.splice(index, 1);
        },

        deleteOption(state, index) {
            state.optionsList['options'].splice(index, 1);
        },

        // Misc
        updateNoDataStatus(state) {
            state.no_data_status = state.datasets.length === 0 && state.optionsList.length === 0;
        },
        mutateLoadingStatus(state, status) {
            state.loading_status = status;
        }
    },
    actions: {
        // make the initial set up of the App based on app locally saved data
        async initialSetUp({ commit, state, dispatch }) {
            // If app is deployed on host with API, dynamically get current hosts url as API url
            if (state.api_on_host == 'true') {
                console.log("API on Host");
                state.api_url = window.location.protocol + '//' + window.location.host + '/api';
                state.api_domain = window.location.protocol + '//' + window.location.host + '/';
            }
            console.log('API URL: ' + state.api_url);
            // console.log('API DOMAIN: ' + state.api_domain);

            // set axios headers required for api requests
            await dispatch('setHeaders');

            // get app settings
            await dispatch('getAppSettings');
        },

        async getAppSettings({ dispatch, state, getters }) {
            let response = await dispatch('apiRequest', { method: 'get', url: 'settings/app_config' });
            if (response.data) {
                // console.log(response.data)
                state.machine_phase_num = response.data.machine_phase_num;
                state.machine_stats_options = response.data.machine_stats_options;
                state.machine_stats_extra = response.data.extra_stats;
                state.charts_options = response.data.charts;
            }
        },
        // set axios request headers
        setHeaders() {
            if (!axios.defaults.headers.common['Content-Type']) {
                axios.defaults.headers.common['Content-Type'] = `application/json`;
            }

            if (!axios.defaults.headers.common['Accept']) {
                axios.defaults.headers.common['Accept'] = `application/json`;
            }

            if (!axios.defaults.headers.common['X-Requested-With']) {
                axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
            }

            // if (!axios.defaults.headers.common['Locale']) {
            //   axios.defaults.headers.common['Locale'] = i18n.locale;
            // }

            if (!axios.defaults.headers.common['Content-Encoding']) {
                axios.defaults.headers.common['Content-Encoding'] = 'gzip';
            }

            // if (!axios.defaults.headers.common['Server-Timing']) {
            //   axios.defaults.headers.common['Server-Timing'] = 'miss, db;dur=53, app;dur=47.2';
            // }
        },

        /**
         * App reset
         */
        // reset indexedDB data
        async resetData({ state, dispatch }) {
            dispatch('showResponseMessage', {
                response_type: 'warning', response: 'Data Reset', response_timeout: 3000
            });

            // clear local data
            await state.app_store.clear();

            // reset store state and set initial data from start
            await dispatch('resetState');
            await dispatch('initialSetUp');
        },

        // reset state data used in authentication
        async resetState({ commit, state, dispatch }) {
            await commit('mutateAccessToken', null);
        },

        /**
         * General actions
         */
        // get response from API call and show the appropriate notification
        async showResponseMessage({ commit, dispatch }, data) {
            let response = [];
            commit('mutateResponse', null);

            // Unauthorized request
            if (data.response.response === undefined && data.response.request !== undefined
                && data.response.request.readyState === 4 && data.response.request.status === 0) {
                await dispatch('logoutProcess');
                return;
            }
            // custom message or warning
            if (['custom_message'].indexOf(data.response_origin) > -1 || data.response_type === 'warning') {
                response = data.response.response.data;

                // internal server error
            } else if (data.response.response === undefined && data.response_type === 'error') {
                response = 'Something went wrong';

                // successful API response
            } else if (data.response_type === 'success') {
                response = data.response.data.data.message;

                // error API response
            } else if (data.response_type === 'error') {
                response = data.response.response.data;
            }

            commit('mutateShowResponse', true);
            commit('mutateResponseType', data.response_type);
            commit('mutateResponseTimeout', data.response_timeout);
            commit('mutateResponse', response);
        },

        // make API request
        async apiRequest({ state, dispatch }, data) {
            let response_data = [];

            try {
                const response = await axios({
                    method: data.method,
                    url: `${state.api_url}/${data.url}`,
                    data: data.data,
                    params: data.params
                });
                if (data.method !== 'get' && !data.hide_response) {
                    dispatch('showResponseMessage', {
                        response_type: 'success', response: response, response_timeout: 2500
                    });
                }
                response_data = response.data;
            } catch (error) {
                dispatch('showResponseMessage', { response_type: 'warning', response: error, response_timeout: 3000 });
                response_data = error;
            }

            axios.defaults.headers.common['Content-Type'] = `application/json`;
            return response_data;
        },

        /**
         * Machines
         */
        async newMachineStatsObj({ state }) {
            return await Object.keys(state.machine_stats_options).reduce((accumulator, val) => {
                return { ...accumulator, [val]: false }
            }, {});
        },

        /**
         Machine data
         */
        // Get machine data for specified optionsoptionsList
        async getMachineData({ state, dispatch }, options) {
            return await dispatch('apiRequest', {
                method: 'get',
                url: 'machine_stats',
                params: {
                    machine: state.machine_name,
                    phases: JSON.stringify(options.phases),
                    stats: JSON.stringify(options.stats),
                    dates: JSON.stringify(options.dates),
                    charts: JSON.stringify(options.charts),
                    charts_options: JSON.stringify(options.charts_options),
                    excludeZeros: options.excludeZeros
                }, hide_response: true
            });
        },
        // Get machine datasets for all optionsets
        async fetchDataSets({ state, dispatch, commit }) {
            commit('mutateLoadingStatus', true);

            let machineDatasets = [];
            if (state.optionsList['options'].length > 0) {
                // Execute API request for each options set & get promises
                const dtsetsPromises = state.optionsList['options'].map(optionset =>
                    dispatch('getMachineData', optionset)
                );
                // Using "Promise.all()", execute the multiple API calls asynchronously to improve performance
                machineDatasets = (await Promise.all(dtsetsPromises)).map((dtset, index) => {
                    if (dtset.data) {
                        return { data: dtset.data, charts: state.optionsList['options'][index].charts }
                    }
                });
            }

            commit('mutateDatasets', machineDatasets);
            commit('mutateLoadingStatus', false);
            commit('updateNoDataStatus');
        },
        // Add new dataset
        async addDataset({ commit, dispatch, getters }, options) {
            commit('addCardRefreshCounter');
            commit('addCardLoadingStatus')
            dispatch('addOption', options);

            const lastIndex = getters.optionsListLength - 1;
            commit('mutateCardLoadingStatus', { index: lastIndex, status: true });

            // 1. Get dataset for specified options
            let dataset = await dispatch('getMachineData', options);
            // 2. Update state & add option to optionset
            if (dataset.data) {
                // Update datasets array to include new dataset
                commit('addDataset', { data: dataset.data, charts: options.charts });
                commit('mutateCardLoadingStatus', { index: lastIndex, status: false });
                commit("incrementCardsRefreshCounter", lastIndex);
            } else {
                dispatch('deleteOption', lastIndex);
                commit('removeCardRefreshCounter', lastIndex);
                commit('removeCardLoadingStatus', lastIndex);
            }

            // If datasets array is empty, enable "no data" message
            commit('updateNoDataStatus');
        },
        // Update existing dataset based on index
        async updateDataset({ commit, dispatch }, payload) {
            if (payload.reRender === true) {
                commit('mutateCardLoadingStatus', { index: payload.index, status: true });
                commit("incrementCardsRefreshCounter", payload.index);
            }

            let newDataset = await dispatch('getMachineData', payload.options);

            if (payload.reRender === true) {
                commit('mutateCardLoadingStatus', { index: payload.index, status: false })
                commit("incrementCardsRefreshCounter", payload.index);
            }

            if (newDataset.data) {
                // Replace old dataset with new one and update datasets & options array
                commit('updateDataset', {
                    'data': { data: newDataset.data, charts: payload.options.charts },
                    'index': payload.index
                });

                dispatch('updateOption', { 'options': payload.options, 'index': payload.index });
            }
            // If datasets array is empty, enable "no data" message
            commit('updateNoDataStatus');

            return newDataset.data
        },
        deleteDataset({ commit, dispatch }, index) {
            commit('deleteDataset', index);
            // Also delete options corresponding to deleted dataset
            dispatch('deleteOption', index);
            commit('removeCardRefreshCounter', index);
            commit('removeCardLoadingStatus', index);
            // If datasets array is empty, enable no data message
            commit('updateNoDataStatus');
        },

        /**
         Options
         */
        async fetchOptions({ state, commit, dispatch }) {
            try {
                const response = await dispatch('apiRequest', {
                    method: 'get',
                    url: 'options',
                    params: { machineName: state.machine_name }
                });
                if (response.data?.options) {
                    if (response.data.options.length > 0) {
                        commit('mutateOptionsList', response.data.options);
                        commit('mutateOptionsFriendlyNames', response.data.friendly_names)
                        commit('initCardsLoadingStatuses');
                        commit('initCardsRefreshCounters');
                        commit('initDatasetsArray');
                    }
                } else {
                    /** This check is performed to accomodate for the change in the options
                        response object due to the addition of the friendly names
                        and ensure no data loss of the previous optionsets
                    */
                    if (response.data.length > 0) {
                        commit('mutateOptionsList', response.data);
                        commit('initCardsLoadingStatuses');
                        commit('initCardsRefreshCounters');
                        commit('initDatasetsArray');
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        async saveOptionsList({ state, dispatch }) {
            const response = await dispatch('apiRequest', {
                method: 'post',
                url: 'update_options',
                data: { data: state.optionsList },
                hide_response: false
            });
        },
        async addOption({ commit, dispatch }, option) {
            commit('addOption', option);
            // After adding options to the list, update local data also
            await dispatch('saveOptionsList');
        },
        async updateOption({ commit, dispatch }, payload) {
            commit('updateOption', { options: payload.options, index: payload.index });
            // After adding options the list, update local data also
            await dispatch('saveOptionsList');
        },
        async deleteOption({ commit, dispatch }, index) {
            commit('deleteOption', index);
            // After removing options from list, update local data also
            await dispatch('saveOptionsList');
        }
    },
})
